import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import '../Content.css';
import { Checkbox} from 'antd';

let grid = {
    'display': 'grid',
    'grid-template-columns': '1fr 1fr 1fr 1fr 1fr 1fr',
    'grid-template-rows': '2fr 2fr 2fr 2fr 1fr',
}


const CheckReport = (props) => {


    const [checkedAll, setCheckedAll] = useState(props.checkBoxList)


    const onChange = (e) => {
        const { value, checked } = e.target
        if (value === 'selectAll') {
            if (checked) {
                let selectAll = checkedAll.map((check) => {
                    return { ...check, isChecked: checked }
                })
                setCheckedAll(selectAll)
                let selectedValArr = selectAll.map((check)=>{
                    return check.value
                })
                props.selected(selectedValArr);
          
            } else {
                let disSelectAll = checkedAll.map((check) => {
                    return { ...check, isChecked: false }
                })

                let removeSelection = disSelectAll.filter((check)=>{
                    if(check.isChecked === true){
                        return ({...check})
                    }
                })

                setCheckedAll(disSelectAll)
                props.selected(removeSelection);
            }
        } else {
            let singleSelect = checkedAll.map((check) => {
                return check.value === value ? { ...check, isChecked: checked } : check
            })
            let getSingleSelect = singleSelect.filter((check)=>{
                if(check.isChecked === true){
                    return check.value
                }
            })
            let getSingleSelectValue = getSingleSelect.map((check)=>{
                return check.value
            })
           
            setCheckedAll(singleSelect)
            props.selected(getSingleSelectValue);
        }
       
    };

    return (
        <>
            <Checkbox onChange={onChange} value={'selectAll'} checked={checkedAll.filter((checkbox) => checkbox?.isChecked !== true).length < 1}>SelectAll</Checkbox>
            <br />
            <br />
            <Row>
                <Col style={grid}>
                    {
                        checkedAll.map((checkList) => {
                            return (
                                <>
                                    <Checkbox className='ms-2' onChange={onChange} checked={checkList?.isChecked || false} value={checkList.value}>{checkList.label}</Checkbox>
                                </>
                            )
                        })
                    }
                </Col>
            </Row>


        </>
    );
}

export default CheckReport;