import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { split, HttpLink } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { createUploadLink } from "apollo-upload-client";
import { ApolloLink } from "@apollo/client";
import Home from "./Components/Home";
import PaymentRequest from "./Components/PaymentRequest";
import SearchPayment from "./Components/SearchPayment";
import Reports from "./Components/Reports";
import PaymentApproval from "./Components/PaymentApproval";
import PaymentsForm from "./Components/PaymentsForm";
import FundOrder from "./Components/FundOrder";
import FundReport from "./Components/FundReport";
import CheckReport from "./Components/reports/CheckReport";
import EditPayment from "./Components/EditPayment";
import ResendEmail from "./Components/ResendEmail";
import LogIn from "./Components/LogIn";
import ForgotPassword from "./Components/ForgotPassword";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ProtectedRoutes, { AuthorizedUsers } from "./Common/ProtectedRoutes";
import { AuthProvider } from "./Context/AuthProvider";
import Fund from "./Components/fund";
import ResetPassword from "./Components/ResetPassword";
import AddUser from "./Components/users/addUserForm";
import { roles } from "./Constants";
import UserTable from "./Components/users/userTable";
import { message } from "antd";
import Checkreport from "./Components/Checkreport";
import Uploader from "./Components/Uploader";

function App() {
  let logOutTime;

  const httpLink = createUploadLink({
    uri: process.env.REACT_APP_NODE_URL + ":4000/graphql",
    headers: { "Apollo-Require-Preflight": "true" },
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("token");
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        token: token ? `${token}` : "",
      },
    };
  });
  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.REACT_APP_NODE_URL_WSS + ":4000/graphql",
      options: {
        reconnect: true,
      },
    })
  );

  const errorlink = onError(
    ({ graphQLErrors, networkError, operation, forward, response }) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          );
          if (message === "Invalid Token!") {
            window.location = "/login";
            localStorage.clear();
          }
        });
      }

      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        //window.location='/login';
        //localStorage.clear()
      }
    }
  );

  const httpLinkErrHandling = ApolloLink.from([errorlink, httpLink]);

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === "OperationDefinition" && operation === "subscription";
    },
    wsLink,
    authLink.concat(httpLinkErrHandling)
  );

  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
  });

  const setTime = () => {
    logOutTime = setTimeout(() => {
      if (window.location.pathname !== "/login") {
        window.location = "/login";
      }
    }, 300 * 1000);
  };

  const resetTime = () => {
    clearTimeEvent();
    setTime();
  };

  const clearTimeEvent = () => {
    if (logOutTime) {
      clearTimeout(logOutTime);
    }
  };

  const autoLogout = () => {
    let events = ["load", "mousemove", "mousedown", "scroll", "keypress"];
    for (const ev of events) {
      window.addEventListener(ev, (e) => {
        resetTime();
      });
    }
    setTime();
  };

  autoLogout();

  return (
    <AuthProvider>
      <ApolloProvider client={client}>
        <div className="App">
          {/* <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}> */}
          <Router>
            <Routes>
              <Route exact path="/login" element={<LogIn />} />
              <Route
                exact
                path="/forgotpassword"
                element={<ForgotPassword />}
              />
              <Route
                exact
                path="/reset-password/:id"
                element={<ResetPassword />}
              />
              <Route
                exact
                path="/create-password/:id"
                element={<ResetPassword />}
              />
              <Route element={<ProtectedRoutes />}>
                <Route element={<AuthorizedUsers role={[roles.superAdmin]} />}>
                  <Route exact path="/add-user" element={<AddUser />} />
                  <Route exact path="/users" element={<UserTable />} />
                  <Route exact path="/upload" element={<Uploader />} />
                </Route>

                <Route
                  element={
                    <AuthorizedUsers
                      role={[roles.superAdmin, roles.BPS_ADMIN]}
                    />
                  }
                >
                  <Route exact path="/editRebate" element={<EditPayment />} />
                  <Route exact path="/resendemail" element={<ResendEmail />} />
                </Route>

                <Route
                  element={
                    <AuthorizedUsers
                      role={[
                        roles.superAdmin,
                        roles.BPS_ADMIN,
                        roles.ACE_HIGH_ADMIN,
                      ]}
                    />
                  }
                >
                  <Route
                    exact
                    path="/searchrebate"
                    element={<SearchPayment />}
                  />
                  <Route exact path="/reports" element={<Reports />} />
                  <Route
                    exact
                    path="/rebateApproval"
                    element={<PaymentApproval />}
                  />
                </Route>

                <Route
                  path="*"
                  exact
                  element={<Navigate to="/searchrebate" replace />}
                />
              </Route>
            </Routes>
          </Router>
          {/* </GoogleOAuthProvider> */}
        </div>
      </ApolloProvider>
    </AuthProvider>
  );
}
export default App;
