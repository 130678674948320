import {React,useState} from "react";
import { Container, Row, Col, Button } from 'react-bootstrap';
import { gql, useMutation } from '@apollo/client'
import { Form, Input, Select, Alert } from 'antd';
import Footer from "../Footer";
import Header from "../Header";
import Navigation from "../Navigation";
import Constants, { patterns, roles, rolesName } from "../../Constants";



const AddUser = () => {

    const [form] = Form.useForm();
    const { Option } = Select;
    const currentRole = localStorage.getItem(Constants.userRole)
    const [alertMessage, setAlertMessage] = useState()

    const resetState = () => {
         setTimeout(() => {
             setAlertMessage()
         }, 3000)
     }
 
    const ADD_USER = gql`
        mutation signUp($username: String!, $password : String!, $name : String!, $email :String!, $phone: String, $userRole : String!) {
            signUp(
            input: {
                username: $username
                password: $password
                name: $name
                email: $email
                phone: $phone
                userRole: $userRole
            }
            ) {
            message
            }
        }`

    const _userRole = [
        {
            value: roles.BPS_ADMIN,
            name: rolesName.BPS_ADMIN
        },
        {
            value: roles.ACE_HIGH_ADMIN,
            name: rolesName.ACE_HIGH_ADMIN
        },
    ]


    const [addUser] = useMutation(ADD_USER);

    const submitHandler = async () => {
        //const { username, name, userRole, email, phone } = form.getFieldValue()
        const { name, userRole, email } = form.getFieldValue()
        const phone='9999999999';
        const username=email;
        try {
            const res = await addUser({
                variables: {
                    username , password: '', name, userRole, email, phone
                }
            })
            if(res){
                setAlertMessage(() => { 
                    window.scrollTo({top:0,left:0,behaviour:"smooth"});
                    return <Alert type="success" message={`User has been successfully added and an enrollment email has been sent`}></Alert> })
                resetState()
    
                form.resetFields()
            }
            
        } catch (err) {
            setAlertMessage(() => { 
                window.scrollTo({top:0,left:0,behaviour:"smooth"});
                return <Alert type="error" message={err.message}></Alert>})
            resetState()

        }
    }

    return (
        <>
            <section className='vh-fill'>
                <Navigation />           
                <Container className='my-3'>
                    <div className="alertmessage w-50">                       
                        {alertMessage}
                    </div> 
                </Container>
                
                <Container className='my-5 py-4'>
                    <Row>
                        <Col>
                            <h4>Add User</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form form={form} layout="vertical" name="dynamic_rule" onFinish={submitHandler}>
                                <Row className="mb-2 pb-2">
                                    
                                    <Col>
                                        <Form.Item
                                            name="userRole"
                                            label="User Role"

                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Select>
                                                {
                                                    currentRole === roles.ACE_HIGH_ADMIN ? _userRole.splice(1).map((role) => (
                                                        <Option value={role.value}>{role.name}</Option>
                                                    )) : _userRole.map((role) => (
                                                        <Option value={role.value}>{role.name}</Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            name="email"
                                            label="Email"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                                {
                                                    type: 'email',
                                                    message: 'Please enter valid email address',
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="mb-3 border-bottom pb-2">
                                    <Col>
                                        <Form.Item
                                            name="name"
                                            label="Name"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                
                                <Row className="justify-content-center text-center pt-4">
                                    <Col lg={12}>
                                        <Form.Item>
                                            <Button type="submit" className="bps-primary-button" >Submit</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </section>
        </>
    )
}


export default AddUser