import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Input, Modal, Form, Button, Select, Radio, Spin, Alert } from "antd";
import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";
import "./Content.css";
import DataTable from "../Common/DataTable";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Constants, {
  paymentType,
  patterns,
  rewardTypes,
  roles,
} from "../Constants";

const EditPayment = () => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const [search, setSearch] = useState();
  const [tableData, setTableData] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [radioSelected, setRadioSelected] = useState(1);
  const [phyDisable, setPhyDisable] = useState(false);
  const [virtualDisable, setvirtualDisable] = useState(false);
  const [check, setcheck] = useState(false);
  const [searchString, setSearchString] = useState();
  const [isRewardTypeChanged, setRewardTypeChanged] = useState(false);
  const [rewardTypeRow, setRewardTypeRow] = useState(false);
  const [currentRole, setCurrentRole] = useState(
    localStorage.getItem(Constants.userRole)
  );
  const [fieldDisabled, setfieldDisabled] = useState(false);
  const [alertMessage, setAlertMessage] = useState();

  const resetState = () => {
    setTimeout(() => {
      setAlertMessage();
    }, 3000);
  };

  const QUERY_PAYMENT = gql`
    query GetIndividualPaymentBySearch($searchString: String!) {
      getIndividualPaymentBySearch(searchString: $searchString) {
        bikeModel
        bikeSerialNumber
        customerFirstName
        customerLastName
        customerAddress1
        customerAddress2
        customerCity
        customerState
        customerZip
        customerEmail
        customerPhoneNumber
        rewardType
        receiptFilename
        trackingNumber
        status
        reason
        paymentAmount
        approvedAtTime
        id
        createdAt
        updatedAt
        redeemedAt
        shippedDate
        redeemed
        submittedBy
        isOrderAssigned
        order {
          funded
        }
        approvedByUser {
          name
        }
      }
    }
  `;

  const GET_PAYMENT_BY_ID = gql`
    query GetIndividualPaymentById($getIndividualPaymentByIdId: String!) {
      getIndividualPaymentById(id: $getIndividualPaymentByIdId) {
        individualPayments {
          paymentAmount
          bikeModel
          bikeSerialNumber
          customerFirstName
          customerLastName
          customerAddress1
          customerAddress2
          customerCity
          customerState
          customerZip
          customerEmail
          customerPhoneNumber
          rewardType
          receiptFilename
          trackingNumber
          status
          reason
          approvedAtTime
          id
          createdAt
          updatedAt
          redeemedAt
          shippedDate
          redeemed
          isOrderAssigned
          order {
            funded
            fundedAt
            status
            id
            createdAt
            s3SignedFileUrl
            reportTotal
            filename
            orderId
          }
        }
      }
    }
  `;

  const EDIT_PAYMENT = gql`
    mutation EditIndividualPaymentById(
      $paymentAmount: Float!
      $customerEmail: String!
      $customerFirstName: String!
      $customerLastName: String!
      $customerAddress1: String!
      $customerAddress2: String
      $customerCity: String!
      $customerState: String!
      $customerZip: String!
      $customerPhoneNumber: String!
      $userId: ID!
      $id: String!
      $rewardType: RewardType!
      $bikeModel: String!
      $bikeSerialNumber: String!
    ) {
      editIndividualPaymentById(
        input: {
          id: $id
          userId: $userId
          bikeModel: $bikeModel
          bikeSerialNumber: $bikeSerialNumber
          customerFirstName: $customerFirstName
          customerLastName: $customerLastName
          customerAddress1: $customerAddress1
          customerAddress2: $customerAddress2
          customerCity: $customerCity
          customerState: $customerState
          customerZip: $customerZip
          customerEmail: $customerEmail
          customerPhoneNumber: $customerPhoneNumber
          rewardType: $rewardType
          paymentAmount: $paymentAmount
        }
      ) {
        customerCity
      }
    }
  `;

  const [getSearch, { loading: getSearchLoading, error, data }] = useLazyQuery(
    QUERY_PAYMENT,
    {
      onCompleted(data) {
        const _IndividualPayArr = data.getIndividualPaymentBySearch;
        setTableData([]);
        _IndividualPayArr.map((_IndividualPay, index) => {
          let createIndividualPayRow = {
            key: index,
            customerFirstName: _IndividualPay.customerFirstName,
            customerLastName: _IndividualPay.customerLastName,
            customerEmail: _IndividualPay.customerEmail,
            status: _IndividualPay.status,
            id: _IndividualPay.id,
            phone: _IndividualPay.customerPhoneNumber,
            paymentAmount: `$ ${_IndividualPay.paymentAmount}`,
            paymentType: _IndividualPay.paymentType,
            mailingAddress1: _IndividualPay.customerAddress1,
            mailingAddress2: _IndividualPay.customerAddress2,
            mailingCity: _IndividualPay.customerCity,
            mailingState: _IndividualPay.customerState,
            mailingZip: _IndividualPay.customerZip,
            trackingNumber: _IndividualPay.trackingNumber,
            isOrderAssigned: _IndividualPay.isOrderAssigned,
            order: _IndividualPay.order,
          };
          setTableData((oldArr) => [...oldArr, createIndividualPayRow]);
        });
      },
      onError(err) {
        console.log("onerror", err);
      },
      fetchPolicy: "network-only",
    }
  );

  const [getDataById, { loading: getDataByIdLoading, err, dataById }] =
    useLazyQuery(GET_PAYMENT_BY_ID, {
      onCompleted(dataById) {
        if (currentRole === roles.BPS_ADMIN) {
          setfieldDisabled(true);
        }
        const res = dataById.getIndividualPaymentById.individualPayments;
        const orderNullCheck = dataById.getIndividualPaymentById.order;
        const isFunded = dataById.getIndividualPaymentById.isOrderAssigned;
        isFunded === false || orderNullCheck === null
          ? setRewardTypeRow(true)
          : setRewardTypeRow(false);
        if (res.paymentAmount > 1000 && res.paymentAmount <= 2500) {
          setPhyDisable(true);
        } else if (res.paymentAmount > 2500) {
          setPhyDisable(true);
          setvirtualDisable(true);
        } else {
          setcheck(false);
          setPhyDisable(false);
          setvirtualDisable(false);
        }
        form.setFieldsValue({
          customerFirstName: res.customerFirstName,
          customerLastName: res.customerLastName,
          customerEmail: res.customerEmail,
          customerPhoneNumber: res.customerPhoneNumber,
          paymentAmount: res.paymentAmount,
          customerAddress1: res.customerAddress1,
          customerAddress2: res.customerAddress2,
          customerCity: res.customerCity,
          customerState: res.customerState,
          customerZip: res.customerZip,
          id: res.id,
          rewardType: res.rewardType,
          trackingNumber: res.trackingNumber,
          bikeModel: res.bikeModel,
          bikeSerialNumber: res.bikeSerialNumber,
        });
      },
      onError(err) {
        console.log("onerror", err);
      },
      fetchPolicy: "network-only",
    });

  const [editPayment] = useMutation(EDIT_PAYMENT);

  const columns = [
    {
      title: "First Name",
      dataIndex: "customerFirstName",
      key: "customerFirstName",
      fixed: "left",
    },
    {
      title: "Last Name",
      dataIndex: "customerLastName",
      key: "customerLastName",
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phnumber",
    },
    {
      title: "Amount",
      dataIndex: "paymentAmount",
      key: "amount",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Tracking Number",
      dataIndex: "trackingNumber",
      key: "trackingNumber",
    },
    {
      title: "Approved Status",
      dataIndex: "status",
      key: "fundingStatus",
    },
    {
      title: "Action",
      render: (_, record) => {
        if (
          record.isOrderAssigned === false &&
          currentRole === roles.BPS_ADMIN
        ) {
          return (
            <>
              <Button type="link" onClick={() => showModal(record.id)}>
                edit payment
              </Button>
              {modal(isModalVisible, handleCancel, Option)}
            </>
          );
        } else if (
          (currentRole === roles.superAdmin &&
            record.isOrderAssigned === false) ||
          (currentRole === roles.superAdmin &&
            record.isOrderAssigned === true &&
            record.order[0]?.funded === false)
        ) {
          return (
            <>
              <Button type="link" onClick={() => showModal(record.id)}>
                edit payment
              </Button>
              {modal(isModalVisible, handleCancel, Option)}
            </>
          );
        } else {
          return (
            <>
              <Button
                type="link"
                hidden={true}
                onClick={() => showModal(record.id)}
              >
                edit payment
              </Button>
              {modal(isModalVisible, handleCancel, Option)}
            </>
          );
        }
      },
    },
  ];

  const showModal = (id) => {
    setIsModalVisible(true);
    getDataById({ variables: { getIndividualPaymentByIdId: id } });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const modal = (isModalVisible, handleCancel, Option) => {
    const handleFormSubmit = async (values) => {
      let userId = localStorage.getItem(Constants.userId);
      const {
        paymentAmount,
        bikeModel,
        bikeSerialNumber,
        customerEmail,
        customerFirstName,
        customerLastName,
        customerAddress1,
        customerAddress2,
        customerCity,
        customerState,
        customerZip,
        customerPhoneNumber,
        rewardType,
        id,
      } = form.getFieldValue();
      try {
        const res = await editPayment({
          variables: {
            id,
            rewardType,
            paymentAmount: +paymentAmount,
            customerEmail,
            customerFirstName,
            customerLastName,
            customerAddress1,
            customerAddress2,
            customerCity,
            customerState,
            customerZip,
            customerPhoneNumber,
            userId: userId,
            bikeModel,
            bikeSerialNumber,
          },
        });
        setAlertMessage(() => {
          window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
          return <Alert type="success" message={`Saved successfully`}></Alert>;
        });
        resetState();
        setIsModalVisible(false);
        getSearch({ variables: { searchString: searchString } });
      } catch (error) {
        setAlertMessage(() => {
          window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
          return <Alert type="error" message={error.message}></Alert>;
        });
        resetState();
      }
    };

    const onRewardTypeChange = (e) => {
      const rewardTypeSelected = e.target.value;
      setRewardTypeChanged(true);
      if (
        (rewardTypeSelected === rewardTypes.check ||
          rewardTypeSelected === rewardTypes.physical) &&
        currentRole === roles.superAdmin
      ) {
        const { mailingAddress1, mailingCity, mailingState, mailingZip } =
          form.getFieldValue();
        if (
          mailingAddress1 === "" ||
          mailingCity === "" ||
          mailingState === "" ||
          mailingZip === ""
        ) {
          setAlertMessage(() => {
            window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
            return (
              <Alert
                type="error"
                message={"Please enter customer address"}
              ></Alert>
            );
          });
          resetState();
        }
      }
    };

    return (
      <>
        <Modal
          title="Edit"
          visible={isModalVisible}
          onOk={form.submit}
          onCancel={handleCancel}
        >
          {getDataByIdLoading ? (
            <Spin
              className="mt-5 d-flex justify-content-center"
              size="large"
            ></Spin>
          ) : (
            <Form
              form={form}
              layout="vertical"
              name="dynamic_rule"
              onFinish={(values) => handleFormSubmit(values)}
            >
              <Row>
                <Col>
                  <Form.Item
                    name={"customerAddress1"}
                    label={"Customer Address 1"}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const rewardtype = getFieldValue("rewardType");
                          if (
                            currentRole === roles.superAdmin &&
                            isRewardTypeChanged === true &&
                            (rewardtype === rewardTypes.check ||
                              rewardtype === rewardTypes.physical) &&
                            (value === "" || value === undefined)
                          ) {
                            return Promise.reject(
                              "please enter mailing address 1"
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name={"customerAddress2"}
                    label={"Customer Address 2"}
                  >
                    <Input disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const rewardtype = getFieldValue("rewardType");
                          if (
                            currentRole === roles.superAdmin &&
                            isRewardTypeChanged === true &&
                            (rewardtype === rewardTypes.check ||
                              rewardtype === rewardTypes.physical) &&
                            (value === "" || value === undefined)
                          ) {
                            return Promise.reject("please enter mailing city");
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                    name={"customerCity"}
                    label={"Customer City"}
                  >
                    <Input disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const rewardtype = getFieldValue("rewardType");
                          if (
                            currentRole === roles.superAdmin &&
                            isRewardTypeChanged === true &&
                            (rewardtype === rewardTypes.check ||
                              rewardtype === rewardTypes.physical) &&
                            (value === "" || value === undefined)
                          ) {
                            return Promise.reject("please enter mailing state");
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                    name={"customerState"}
                    label={"Customer State"}
                  >
                    <Input disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const rewardtype = getFieldValue("rewardType");
                          if (
                            currentRole === roles.superAdmin &&
                            isRewardTypeChanged === true &&
                            (rewardtype === rewardTypes.check ||
                              rewardtype === rewardTypes.physical) &&
                            (value === "" || value === undefined)
                          ) {
                            return Promise.reject("please enter bike model");
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                    name={"bikeModel"}
                    label={"Bike Model"}
                  >
                    <Input disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const rewardtype = getFieldValue("rewardType");
                          if (
                            currentRole === roles.superAdmin &&
                            isRewardTypeChanged === true &&
                            (rewardtype === rewardTypes.check ||
                              rewardtype === rewardTypes.physical) &&
                            (value === "" || value === undefined)
                          ) {
                            return Promise.reject("please enter mailing zip");
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                    name={"customerZip"}
                    label={"Customer Zip"}
                  >
                    <Input disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name={"customerFirstName"}
                    label={"Customer First Name"}
                  >
                    <Input disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name={"customerLastName"}
                    label={"Customer Last Name"}
                  >
                    <Input disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item name={"customerEmail"} label={"Customer Email"}>
                    <Input disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item name={"paymentAmount"} label={"Payment Amount"}>
                    <Input disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name={"customerPhoneNumber"}
                    label={"Customer Phone Number"}
                    rules={[
                      {
                        pattern: patterns.phoneNumberCheckPattern,
                        message: "Value should contain just number.",
                      },
                      {
                        min: 10,
                        message: "Phone number should be of 10 digit long.",
                      },
                      {
                        max: 10,
                        message: "Phone number should be of 10 digit long.",
                      },
                    ]}
                  >
                    <Input disabled={fieldDisabled} />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Form.Item
                  label={"Reward Type"}
                  name={"rewardType"}
                  hidden={rewardTypeRow}
                >
                  <Radio.Group onChange={onRewardTypeChange}>
                    <Radio disabled={phyDisable} value={rewardTypes.physical}>
                      Physical
                    </Radio>
                    <Radio
                      disabled={virtualDisable}
                      value={rewardTypes.virtual}
                    >
                      Virtual
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>
              <Row className="alertmessage m-1">{alertMessage}</Row>
            </Form>
          )}
        </Modal>
      </>
    );
  };

  return (
    <>
      <section className="vh-fill">
        <Navigation />

        <Container className="my-5 py-4">
          <Row>
            <Col>
              <h4>Edit Payment</h4>
            </Col>
          </Row>

          <Row>
            <Input.Search
              allowClear
              className="w-25"
              onSearch={(e) => {
                setSearchString(e);
                getSearch({ variables: { searchString: e } });
              }}
            />
          </Row>
          <Row className="alertmessage m-1">
            <Col md={6}>{alertMessage}</Col>
          </Row>
          <Row>
            {getSearchLoading ? (
              <Spin
                className="mt-5 d-flex justify-content-center"
                size="large"
              ></Spin>
            ) : (
              <DataTable
                showCheck={false}
                columnData={columns}
                tableData={tableData}
                scrollX={2300}
                scrollY={500}
              />
            )}
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default EditPayment;
