import React, {Fragment} from 'react';
import 'antd/dist/antd.css';
import { Container, Col, Row } from 'react-bootstrap';
import './Footer.css';


const Footer = () => {

    return (
        <section className='footer pt-3'>
           <Container>
                <Row className='justify-content-center pb-2'>
                    <Col>
                        <p>Card / Virtual card is issued by Pathward, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. No cash access or recurring payments. Card can be used everywhere Visa debit cards are accepted. Virtual card can be used everywhere Visa debit cards are accepted online, or phone/mail orders. Card/Virtual card valid for up to 6/24 months; unused funds will forfeit after the valid thru date. Terms and conditions apply</p>
                    </Col>
                </Row>
                <Row className='justify-content-center flex-column'>
                    <Col className="d-flex flex-row justify-content-center">
                        <ul className='footer-nav text-center text-sm-start'>
                            <li>
                                <a href='https://powur.com/privacy-policy.html' target={'_blank'} className='footer-link me-4'>Privacy Policy</a>
                            </li>
                            <li>
                                <a href='https://powur-public.s3.us-west-1.amazonaws.com/purchase_terms_and_conditions/Independent-Powur-Seller-Application-v2.0.pdf'  target={'_blank'}  className='footer-link'>Terms of Service</a>
                            </li>
                        </ul>
                    </Col>
                    <Col  className="d-flex flex-row justify-content-center">
                        <p className='text-center text-sm-end'>© Powur 2022</p>
                    </Col>
                </Row>
           </Container>
        </section>
    );
}

export default Footer;