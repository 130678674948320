const Constants = {
    email: 'email',
    picture: 'picture',
    name: 'given_name',
    loggedIn: 'loggedIn',
    userId: 'userId',
    token: 'token',
    userRole: 'userRole'

}
export default Constants;


export const roles = {
    superAdmin: 'ROOT_ADMIN',
    BPS_ADMIN: 'BPS_ADMIN',
    ACE_HIGH_ADMIN: 'ACE_HIGH_ADMIN',

}

export const rolesName ={
    superAdmin: 'ROOT ADMIN',
    BPS_ADMIN: 'BPS ADMIN',
    ACE_HIGH_ADMIN: 'ACE HIGH ADMIN',
}

export const paymentType = [
    {
        type: 'PTO Delay/Loan Payment Reimb',
        memo: 'Loan Reminbursement'
    },
    {
        type: 'Project Delays',
        memo: 'Customer Satisfaction for delays'
    },
    {
        type: 'Property Damage',
        memo: 'cashing provides release of future claims'
    },
    {
        type: 'Utility Rebate ReimbPre PTO - Customer Satisfaction',
        memo: 'Utlity Reimbursement customer satisfaction'
    },
    {
        type: 'Pre PTO - Customer Satisfaction',
        memo: 'Customer Satisfaction '
    },
    {
        type: 'COGS - Upgrade/Tree Reimb',
        memo: '--------'
    },
    {
        type: 'Post PTO - Appeasement',
        memo: 'Customer Satisfaction '
    },
    {
        type: 'Post PTO - Production Issues',
        memo: 'cashing provides release of future claims'
    },
    {
        type: 'others',
        memo: '---------'
    },
]

export const patterns = {
    specialCharacterCheckPatternWithSpace: /^[a-z0-9\s_.-]+$/i,
    specialCharacterCheckPattern: /^[a-z0-9_.-]+$/i,
    phoneNumberCheckPattern: /^(?:\d*)$/,
    passwordCheck:/^(?=.*?[A-Z])(?=.*?[#?!@$%^&*-]).{8,}$/,
    zipCheck:/^[0-9]*$/,
}

export const paginaion = {
    pageSize: 10
}

export const cities = [
    {
        name: 'Alabama',
        value: 'AL'
    },
    {
        name: 'Alaska',
        value: 'AK'
    },
    {
        name: 'Arizona',
        value: 'AZ'
    },
    {
        name: 'Arkansas',
        value: 'AR'
    },
    {
        name: 'California',
        value: 'CA'
    },
    {
        name: 'Colorado',
        value: 'CO'
    },
    {
        name: 'Connecticut',
        value: 'CT'
    },
    {
        name: 'Delaware',
        value: 'DE'
    },
    {
        name: 'District of Columbia',
        value: 'DC'
    },
    {
        name: 'Florida',
        value: 'FL'
    },
    {
        name: 'Georgia',
        value: 'GA'
    },
    {
        name: 'Hawaii',
        value: 'HI'
    },
    {
        name: 'Idaho',
        value: 'ID'
    },
    {
        name: 'Illinois',
        value: 'IL'
    },
    {
        name: 'Indiana',
        value: 'IN'
    },
    {
        name: 'Iowa',
        value: 'IO'
    },
    {
        name: 'Kansas',
        value: 'KS'
    },
    {
        name: 'Kentucky',
        value: 'KY'
    },
    {
        name: 'Louisiana',
        value: 'LA'
    },
    {
        name: 'Maine',
        value: 'ME'
    },
    {
        name: 'Maryland',
        value: 'MD'
    },
    {
        name: 'Massachusetts',
        value: 'MA'
    },
    {
        name: 'Michigan',
        value: 'MI'
    },
    {
        name: 'Minnesota',
        value: 'MN'
    },
    {
        name: 'Mississippi',
        value: 'MS'
    },
    {
        name: 'Missouri',
        value: 'MS'
    },
    {
        name: 'Montana',
        value: 'MT'
    },
    {
        name: 'Nebaraska',
        value: 'NE'
    },
    {
        name: 'Nevada',
        value: 'NV'
    },
    {
        name: 'New Hampshire',
        value: 'NH'
    },
    {
        name: 'New Jersey',
        value: 'NJ'
    },
    {
        name: 'New Mexico',
        value: 'NM'
    },
    {
        name: 'New York',
        value: 'NY'
    },
    {
        name: 'North Carolina',
        value: 'NC'
    },
    {
        name: 'North Dakota',
        value: 'ND'
    },
    {
        name: 'Ohio',
        value: 'OH'
    },
    {
        name: 'Oklahoma',
        value: 'OK'
    },
    {
        name: 'Oregon',
        value: 'OR'
    },
    {
        name: 'Pennsylvania',
        value: 'PA'
    },
    {
        name: 'Rhode Island',
        value: 'RI'
    },
    {
        name: 'South Carolina',
        value: 'SC'
    },
    {
        name: 'South Dakota',
        value: 'SD'
    },
    {
        name: 'Tennessee',
        value: 'TN'
    },
    {
        name: 'Texas',
        value: 'TX'
    },
    {
        name: 'Utah',
        value: 'UT'
    },
    {
        name: 'Vermont',
        value: 'VT'
    },
    {
        name: 'Virginia',
        value: 'VA'
    },
    {
        name: 'Washington',
        value: 'WA'
    },
    {
        name: 'West Virginia',
        value: 'WV'
    },
    {
        name: 'Wisconsin',
        value: 'WI'
    },
    {
        name: 'Wyoming',
        value: 'WY'
    },

]


export const currentPath = {
    fundOrder: '/fundorder',
    fundReport: '/fundreport'
}


export const rewardTypes={
physical:"visareward",
virtual:"virtualvisa",
check:"check",
}

export const displayRewardTypeName={
    PHYSICAL_ALIAS: 'Physical Visa Reward Card',
    VIRTUAL_ALIAS: 'Virtual Visa Reward Card',
    CHECK_ALIAS: 'Check',
}

export const approvalStatus={
    approved : "APPROVED",
    declined : "DECLINED"
}