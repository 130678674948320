import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Button, Input, message, Select, Form, Tabs, Spin, Alert } from "antd";
import Navigation from "./Navigation";
import Header from "./Header";
import Footer from "./Footer";
import { gql, useQuery, useLazyQuery, useMutation } from "@apollo/client";
import "./Content.css";
import DataTable from "../Common/DataTable";
import Constants, { approvalStatus } from "../Constants";

const PaymentApproval = () => {
  const { Option } = Select;
  const [tableData, setTableData] = useState([]);
  const [rowData, setrowData] = useState();
  const [statusVal, setStatus] = useState("pending");
  const [filterType, setfilterType] = useState("");
  const [value, setvalue] = useState("");
  const [sortCreatedAt, setsortCreatedAt] = useState(-1);
  const [form] = Form.useForm();
  const { TabPane } = Tabs;
  const [totalPages, setTotalPages] = useState();
  const [reasonSelected, setReasonSelected] = useState();
  const [hidden, setHidden] = useState(false);
  const [ids, setIds] = useState([]);
  const [alertMessage, setAlertMessage] = useState();

  const resetState = () => {
    setTimeout(() => {
      setAlertMessage();
    }, 3000);
  };

  const INDIVIDUAL_PAYMENT_LIST = gql`
    query GetAllIndividualPayment(
      $filterType: String
      $value: String
      $sortCreatedAt: Int
      $statusVal: String
      $page: Int
    ) {
      getAllIndividualPayment(
        filterType: $filterType
        value: $value
        sortCreatedAt: $sortCreatedAt
        statusVal: $statusVal
        page: $page
      ) {
        individualPayments {
          bikeModel
          bikeSerialNumber
          customerFirstName
          customerLastName
          customerAddress1
          customerAddress2
          customerCity
          customerState
          customerZip
          customerEmail
          customerPhoneNumber
          rewardType
          receiptFilename
          trackingNumber
          status
          reason
          approvedAtTime
          id
          createdAt
          updatedAt
          redeemedAt
          shippedDate
          redeemed
          submittedBy
          receiptDownloadUrl
          paymentAmount
          isOrderAssigned
          approvedByUser {
            name
          }
        }
        totalPages
        totalDocs
        currentPage
        limit
      }
    }
  `;

  const APPROVE_DECLINE = gql`
    mutation approveIndividualPayment(
      $individualPayments: [SelectedIndividualPayment!]!
      $userId: String!
      $atTime: String!
    ) {
      approveIndividualPayment(
        input: {
          individualPayments: $individualPayments
          userId: $userId
          atTime: $atTime
        }
      ) {
        message
      }
    }
  `;

  const [paymentApproval] = useMutation(APPROVE_DECLINE);
  const [getupdatedIndiPay, { loading, error, data }] = useLazyQuery(
    INDIVIDUAL_PAYMENT_LIST,
    {
      onCompleted(data) {
        const _IndividualPayArr =
          data.getAllIndividualPayment.individualPayments;
        setTableData([]);
        setTotalPages({
          currentPage: data.getAllIndividualPayment.currentPage,
          limit: data.getAllIndividualPayment.limit,
          totalDocs: data.getAllIndividualPayment.totalDocs,
        });
        _IndividualPayArr.map((_IndividualPay, index) => {
          let createIndividualPayRow = {
            key: index,
            declineReason: _IndividualPay.reason,
            customerFirstName: _IndividualPay.customerFirstName,
            customerLastName: _IndividualPay.customerLastName,
            customerEmail: _IndividualPay.customerEmail,
            status: _IndividualPay.status,
            id: _IndividualPay.id,
            paymentAmount: `$ ${_IndividualPay.paymentAmount}`,
            bikeModel: _IndividualPay.bikeModel,
            bikeSerialNumber: _IndividualPay.bikeSerialNumber,
            createdAt: _IndividualPay.createdAt,
            trackingNumber: _IndividualPay.trackingNumber,
            approvedAtTime: _IndividualPay.approvedAtTime,
            approvedByUser: _IndividualPay.approvedByUser?.name,
            phone: _IndividualPay.customerPhoneNumber,
            mailingAddress1: _IndividualPay.customerAddress1,
            mailingAddress2: _IndividualPay.customerAddress2,
            mailingCity: _IndividualPay.customerCity,
            mailingState: _IndividualPay.customerState,
            mailingZip: _IndividualPay.customerZip,
            receiptDownloadUrl: _IndividualPay.receiptDownloadUrl,
          };
          setTableData((oldArr) => [...oldArr, createIndividualPayRow]);
          form.resetFields();
        });
      },
      onError(err) {
        setAlertMessage(() => {
          return <Alert type="warning" message={err.message}></Alert>;
        });
        resetState();
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    getupdatedIndiPay({
      variables: {
        filterType: filterType,
        value: value,
        sortCreatedAt: sortCreatedAt,
        statusVal: statusVal,
      },
    });
  }, [filterType, value, sortCreatedAt, statusVal]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "customerFirstName",
      key: "firstName",
      fixed: "left",
    },
    {
      title: "Last Name",
      dataIndex: "customerLastName",
      key: "lastName",
      fixed: "left",
    },
    {
      title: "Decline Reason",
      dataIndex: "declineReason",
      key: "declineReason",
      fixed: "left",
      hidden: true,
      render: (text, record) => {
        if (statusVal !== "pending") {
          return <>{text}</>;
        } else {
          return (
            <Col>
              <Form.Item
                name={"declineReason"}
                rules={[
                  {
                    required: true,
                    message: "field is required*",
                  },
                ]}
              >
                <Select
                  name={"declineReason"}
                  onSelect={(value, event) => {
                    form.setFieldsValue({
                      declineReason: value,
                      status: approvalStatus.declined,
                    });
                    record.declineReason = form.getFieldValue().declineReason;
                    setReasonSelected(record.declineReason);
                    setIds((oldArr) => [...oldArr, record.id]);
                  }}
                >
                  <Option value={"Management Declined"}>
                    {"Management Declined"}
                  </Option>
                </Select>
                <span
                  className={form.isFieldValidating() ? "d-block" : "d-none"}
                >
                  This field is required
                </span>
              </Form.Item>
            </Col>
          );
        }
      },
    },
    {
      title: "Email",
      dataIndex: "customerEmail",
      key: "email",
    },

    {
      title: "Date Submitted",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Amount",
      dataIndex: "paymentAmount",
      key: "amount",
    },
    {
      title: "Bike Model",
      dataIndex: "bikeModel",
      key: "bikeModel",
    },
    {
      title: "Bike Serial Number",
      dataIndex: "bikeSerialNumber",
      key: "bikeSerialNumber",
    },
    {
      title: "Download Receipt",
      dataIndex: "receiptDownloadUrl",
      key: "receiptDownloadUrl",
      render: (_, record) => {
        return (
          <>
            <a target={"_blank"} href={`${record.receiptDownloadUrl}`} download>
              Download
            </a>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Tracking Number",
      dataIndex: "trackingNumber",
      key: "trackingNumber",
    },
    {
      title: "Approved/Declined Date",
      dataIndex: "approvedAtTime",
      key: "approvedAtTime",
    },
    {
      title: "Approved/Declined By",
      dataIndex: "approvedByUser",
      key: "id",
    },
    {
      title: " Customer Address 1",
      dataIndex: "mailingAddress1",
      key: "mailingAddress1",
    },
    {
      title: " Customer Address 2",
      dataIndex: "mailingAddress2",
      key: "mailingAddress2",
    },
    {
      title: "Customer City",
      dataIndex: "mailingCity",
      key: "mailingCity",
    },
    {
      title: "Customer State",
      dataIndex: "mailingState",
      key: "mailingState",
    },
    {
      title: "Customer Zip",
      dataIndex: "mailingZip",
      key: "mailingZip",
    },
  ];

  const getRowDataHandler = (data, reason, stat) => {
    let rowDataObj = [];
    for (const row of data) {
      if (
        stat === approvalStatus.declined &&
        (reason === "" || reason === undefined)
      ) {
        setAlertMessage(() => {
          return (
            <Alert
              type="warning"
              message={`please select the reason for selected row`}
            ></Alert>
          );
        });
      } else {
        rowDataObj.push({
          id: row.id,
          reason: reason,
          status: stat,
        });
      }
    }
    setrowData(rowDataObj);
    setAlertMessage();
    return rowDataObj;
  };

  const paymentApprovalHandler = async (value) => {
    if (rowData === undefined || rowData.length === 0) {
      setAlertMessage(() => {
        return <Alert type="warning" message={`please select the row`}></Alert>;
      });
    } else {
      setAlertMessage();
      let currentData = new Date().toISOString();
      let userId = localStorage.getItem(Constants.userId);
      let data;
      if (value === approvalStatus.declined) {
        if (reasonSelected === undefined) {
          setAlertMessage(() => {
            return (
              <Alert
                type="error"
                message={`Please select the reason first`}
              ></Alert>
            );
          });
          resetState();
        } else {
          let check = rowData.every((row) => {
            return ids.includes(row.id);
          });
          if (check) {
            data = getRowDataHandler(
              rowData,
              reasonSelected,
              approvalStatus.declined
            );
            setIds([]);
          } else {
            setAlertMessage(() => {
              return (
                <Alert
                  type="error"
                  message={`Please select the declined reason for selected rows`}
                ></Alert>
              );
            });
            resetState();
          }
        }
      } else {
        data = getRowDataHandler(rowData, "", approvalStatus.approved);
      }
      if (data !== undefined) {
        try {
          const res = await paymentApproval({
            variables: {
              individualPayments: data,
              userId: userId,
              atTime: currentData,
            },
          });
          getupdatedIndiPay({
            variables: {
              filterType: filterType,
              value: value,
              sortCreatedAt: sortCreatedAt,
              statusVal: statusVal,
            },
          });
          setAlertMessage(() => {
            return (
              <Alert
                type="success"
                message={`Your payment has been successfully ${value}`}
              ></Alert>
            );
          });
          setReasonSelected();
          resetState();
        } catch (err) {
          setAlertMessage(() => {
            return <Alert type="error" message={err.message}></Alert>;
          });
          resetState();
        }
      }
    }
  };

  const switchTabHandler = (key) => {
    switch (key) {
      case "1":
        setStatus("pending");
        setHidden(false);
        break;
      case "2":
        setStatus("approved");
        setHidden(true);
        break;
      case "3":
        setStatus("declined");
        setHidden(true);
        break;
      default:
        setStatus("pending");
    }
  };

  return (
    <>
      <section className="vh-fill">
        <Navigation />
        <Container className="my-2 py-4">
          <Row>
            <Col>
              <h4>Payment Approval</h4>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Button
                hidden={hidden}
                onClick={() => paymentApprovalHandler(approvalStatus.approved)}
                className="me-3"
                type="primary"
              >
                Approve
              </Button>
              <Button
                hidden={hidden}
                onClick={() => paymentApprovalHandler(approvalStatus.declined)}
                type="primary"
                danger
              >
                Decline
              </Button>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Select
                showSearch
                placeholder="Select a rebate field"
                optionFilterProp="children"
                className="me-3 w-25"
                onChange={(e) => {
                  setfilterType(e);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {columns.map((col) => {
                  if (
                    col.dataIndex !== "receiptDownloadUrl" &&
                    col.dataIndex !== "approvedByUser"
                  ) {
                    return <Option value={col.dataIndex}>{col.title}</Option>;
                  }
                })}
              </Select>
              <Input.Search
                allowClear
                className="w-25"
                onSearch={(e) => setvalue(e)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6} style={{ height: "40px" }}>
              {alertMessage}
            </Col>
          </Row>
          <Row>
            <Col>
              <Tabs defaultActiveKey="1" onChange={switchTabHandler} right>
                <TabPane tab="Pending" key="1">
                  <Form form={form}>
                    {loading ? (
                      <Spin
                        className="mt-5 d-flex justify-content-center"
                        size="large"
                      ></Spin>
                    ) : (
                      <DataTable
                        showCheck={true}
                        totalPages={totalPages}
                        currentPage={(cp) => {
                          getupdatedIndiPay({
                            variables: {
                              filterType: filterType,
                              value: value,
                              statusVal: statusVal,
                              page: cp,
                            },
                          });
                        }}
                        rowdata={(rowData) => {
                          getRowDataHandler(rowData, "", "");
                        }}
                        columnData={columns}
                        tableData={tableData}
                        scrollX={4300}
                        scrollY={500}
                      />
                    )}
                  </Form>
                </TabPane>
                <TabPane tab="Approved" key="2">
                  <Form form={form}>
                    <DataTable
                      showCheck={false}
                      totalPages={totalPages}
                      currentPage={(cp) => {
                        getupdatedIndiPay({
                          variables: {
                            filterType: filterType,
                            value: value,
                            statusVal: statusVal,
                            page: cp,
                          },
                        });
                      }}
                      columnData={columns.filter((col) => !col.hidden)}
                      tableData={tableData}
                      scrollX={4300}
                      scrollY={500}
                    />
                  </Form>
                </TabPane>
                <TabPane tab="Declined" key="3">
                  <Form form={form}>
                    <DataTable
                      showCheck={false}
                      totalPages={totalPages}
                      currentPage={(cp) => {
                        getupdatedIndiPay({
                          variables: {
                            filterType: filterType,
                            value: value,
                            statusVal: statusVal,
                            page: cp,
                          },
                        });
                      }}
                      columnData={columns}
                      tableData={tableData}
                      scrollX={4300}
                      scrollY={500}
                    />
                  </Form>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default PaymentApproval;
